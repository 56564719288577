import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"
import { Modal } from "react-bootstrap"
import Cookies from "universal-cookie"

import WebriQForm from "@webriq/gatsby-webriq-form"
// import qs from "query-string"

const cookies = new Cookies()

function KBPopup() {
  const [show, setShow] = useState(false)
  const [thanks, setThanks] = useState(false)
  // const [cookieValue, setCookieValue] = useState("")
  const [currentCookie, setCurrentCookie] = useState(cookies.get("cookieName"))

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")

  function handleNameChange(e) {
    setName(e.target.value)
  }

  function handleEmailChange(e) {
    setEmail(e.target.value)
  }
  function handlePhoneChange(e) {
    setPhone(e.target.value)
  }
  function handleCompanyChange(e) {
    setCompany(e.target.value)
  }

  const handleClose = () => setShow(false)
  const handleOpen2 = () => setThanks(true)
  const handleClose2 = () => setThanks(false)

  const isEnabled =
    name.length > 0 &&
    email.length > 0 &&
    phone.length > 0 &&
    company.length > 0

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true)
    }, 40000)
    return () => clearTimeout(timer)
  }, [])

  const handleCookie = () => {
    cookies.set("cookieName", "hidden", {
      expires: new Date(new Date().getTime() + 3600 * 1000),
    })
    // console.log(cookieValue)
    // setCurrentCookie(() => {
    // 	currentCookie = cookies.get("cookieName")
    // })
  }

  const ifSubmit = () => {
    if (currentCookie !== setCurrentCookie || currentCookie === undefined) {
      return (
        <div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Exclusive Knowledge Base Community!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <WebriQForm
                id="exclusive-kb-popup"
                name="Exclusive Knowledge Base"
                className="exclusiveKBPopup"
                data-form-id="22e30370-78bd-4ece-8dfd-3ce8d4e2182d"
                data-thankyou-url="/knowledge-base/"
              >
                <div className="row">
                  <div className="form-group col-lg-12">
                    <input
                      type="text"
                      name="Name"
                      size={40}
                      className="form-control required-bold"
                      placeholder="Full Name"
                      id="name"
                      required
                      onChange={handleNameChange}
                    />
                  </div>
                  <div className="form-group col-lg-12">
                    <input
                      type="email"
                      name="Email"
                      size={40}
                      className="form-control required-bold"
                      placeholder="Email"
                      id="email"
                      required
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="form-group col-lg-12">
                    <input
                      type="text"
                      name="Phone Number"
                      size={40}
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number"
                      onChange={handlePhoneChange}
                    />
                  </div>
                  <div className="form-group col-lg-12">
                    <input
                      type="text"
                      name="Company"
                      size={40}
                      className="form-control"
                      id="company"
                      placeholder="Company"
                      onChange={handleCompanyChange}
                    />
                  </div>
                  <div className="form-group form-group col-12 d-flex justify-content-center">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group col-12 text-center">
                    <div className="input-filled">
                      <button
                        type="submit"
                        className="btn btn-primary btn-arrow"
                        onClick={() => {
                          handleClose()
                          handleOpen2()
                          handleCookie()
                        }}
                        aria-hidden="true"
                        disabled={!isEnabled}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </WebriQForm>
            </Modal.Body>
          </Modal>
          <Modal show={thanks} onHide={handleClose2} id="thank-you">
            <Modal.Body>
              <h3 className="text-center mb-5">
                Thanks we received your message. We will be in touch soon.
              </h3>
              <div className="d-flex mt-2 justify-content-center"></div>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return null
    }
  }

  return <div> {ifSubmit()} </div>
}

export default KBPopup
